<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title v-if="workstudyList.length === 0">Class Schedule</v-toolbar-title>
        <v-menu v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              {{ selectedScheduleTitle }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :disabled="selectedSchedule === 'student'" @click="setSelectedSchedule('student')">
              <v-list-item-title>Student Class Schedule</v-list-item-title>
            </v-list-item>
            <v-list-item v-for="({ code, title }, index) in workstudyList" :key="'workstudy-' + index" :disabled="selectedSchedule === code" @click="setSelectedSchedule(code)">
              <v-list-item-title>{{ title }} Schedule</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-autocomplete v-if="isEmployee && selectedSchedule === 'student'" v-model="selectedStudent" :items="students" :search-input.sync="studentSearch" :filter="(item, queryText, itemText) => { return true }" label="Student Search" hide-details>
          <template v-slot:append-outer>
            <v-tooltip v-if="selectedStudent" top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="clearStudent()" style="margin-top: -4px">
                  <v-icon>fal fa-times-circle</v-icon>
                </v-btn>
              </template>
              <span>Clear student</span>
            </v-tooltip>
          </template>
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-menu v-if="isEmployee && selectedSchedule === 'student'" bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :disabled="termOptions.length <= 1" text>
              {{ termText }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(item, index) in termOptions" :key="'term-' + index" @click="term = item.term; termText = item.label">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
              <v-list-item-action v-if="isEmployee && item.active">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">far fa-check</v-icon>
                  </template>
                  <span>Student is registered for this term</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-card-text v-if="noClasses">
        <p>It looks like {{ isEmployee ? 'this student is' : 'you are' }} not registered for any classes for the selected term.</p>
        <p v-if="!isEmployee">If you recently registered for your classes in Banner then please allow a few hours for your schedule to feed to the Portal.</p>
        <p v-if="!isEmployee">If you are a new student who recently submitted your pre-registration form, then you may need to wait a few days for your schedule to feed to the Portal.</p>
      </v-card-text>
      <template v-else-if="selectedSchedule === 'student'">
        <v-tabs v-model="tab">
          <v-tab>
            <v-icon v-if="!isSmall" left>fas fa-list</v-icon>
            List View
          </v-tab>
          <v-tab>
            <v-icon v-if="!isSmall" left>fas fa-calendar</v-icon>
            Calendar View
          </v-tab>
          <v-spacer></v-spacer>
          <v-btn v-if="!isSmall" href="https://www.covenant.edu/pdf/visit/campus_map.pdf" target="_blank" text>
            <v-icon left>fas fa-map-marked</v-icon>
            Campus Map
          </v-btn>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-list>
              <v-list-item v-for="(course, index) in schedule" :key="'course-'+index">
                <v-list-item-avatar v-if="course.regStatus === 'WL'">
                  <v-icon color="error">fas fa-exclamation-triangle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ course.title }}</v-list-item-title>
                  <v-list-item-subtitle><b>Meets: </b>{{ meetText(course.meetingBase) }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="roomText(course.meetingBase)" v-html="roomText(course.meetingBase)"></v-list-item-subtitle>
                  <v-list-item-subtitle v-if="course.instructor.name"><b>Instructor: </b>{{ course.instructor.name }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="course.regStatus === 'WL'" style="font-weight:bold">{{ isEmployee ? 'Student is' : 'You are' }} currently wait-listed for this course</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle>Total credit hours: {{ credits }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-tab-item>
          <v-tab-item>
            <v-calendar ref="calendar" type="week" :weekdays="[1,2,3,4,5,6]" :events="courseEvents" :first-interval="7" :interval-minutes="60" :interval-count="14" :interval-height="48" :short-weekdays="true" :start="calendarStart" :event-color="(event) => { return event.color }" @click:event="showCalendarCourseDetails" style="max-height:700px">
            </v-calendar>
            <v-menu v-model="eventMenuActive" :activator="selectedElement" offset-y>
              <v-card color="grey lighten-4" min-width="350px" max-width="450px" flat>
                <v-toolbar :color="selectedEvent.regStatus === 'WL' ? 'red' : 'primary'" dark>
                  <v-toolbar-title>{{ selectedEvent.title ? selectedEvent.title.split(' - ')[0] : '' }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <h3 v-if="selectedEvent.regStatus === 'WL'" style="margin-bottom:1em">You are currently waitlisted for this course.</h3>
                  <div><b>Title: </b>{{ selectedEvent.title && selectedEvent.title.split(' - ').length > 1 ? selectedEvent.title.split(' - ')[1] : '' }}</div>
                  <div><b>Meets: </b>{{ meetText(selectedEvent.meetingBase) }}</div>
                  <div v-html="roomText(selectedEvent.meetingBase)"></div>
                  <div><b>Instructor: </b>{{ selectedEvent.instructor && selectedEvent.instructor.name ? selectedEvent.instructor.name : '' }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="eventMenuActive = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-else>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-btn icon :disabled="workstudyCalendarDay === 'Sunday'" @click="prevDay">
            <v-icon>fal fa-chevron-left</v-icon>
          </v-btn>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                {{ workstudyCalendarDay }}
                <v-icon right>fal fa-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="({ text, value }, index) in workstudyCalendarDays" :key="'days-' + index" @click="workstudyCalendarDate = value">
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn icon :disabled="workstudyCalendarDay === 'Saturday'" @click="nextDay">
            <v-icon>fal fa-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-calendar v-model="workstudyCalendarDate" ref="workstudyCalendar" type="category" category-show-all :categories="workstudyStudents" :events="workstudyEvents" :first-interval="7" :interval-minutes="60" :interval-count="14" :interval-height="48" :short-weekdays="true" :start="calendarStart" :event-color="(event) => { return event.color }" @click:event="showCalendarCourseDetails" style="max-height:700px"></v-calendar>
      </template>
    </v-card>
  </v-container>
</template>
<style>
.v-calendar.theme--dark.v-calendar-events .v-event-timed.primary {
  color: #1E1E1E !important
}
.v-calendar.theme--dark.v-calendar-events .v-event-timed.grey {
  color: black !important
}
.v-calendar .theme--dark.v-card.grey.lighten-4 {
  background-color: #1E1E1E !important;
  color: white;
}
.v-calendar .theme--dark.v-card.grey.lighten-4 .v-toolbar__title {
  color: #1E1E1E !important;
}
.v-calendar .v-calendar-daily.v-calendar-category .v-calendar-daily_head-day-label, .v-calendar-daily.v-calendar-category .v-calendar-daily_head-weekday {
  display: none;
}
.v-calendar .v-calendar-daily.v-calendar-category .v-calendar-category__column-header {
  padding: .3em 0;
}
.v-calendar .v-event-timed {
  white-space: pre-wrap;
}
.v-menu__content.theme--dark .v-card.theme--dark.grey.lighten-4 {
  background-color: #1E1E1E !important;
  color: white;
}
.v-menu__content.theme--dark .v-card.theme--dark .v-toolbar.theme--dark.primary {
  color: #1E1E1E !important;
}
</style>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const id = computed(() => root.$route.params.id)
    const term = computed({
      get: () => root.$store.state.calendar.term,
      set: (val) => root.$store.commit('calendar/setTerm', val)
    })
    const termText = computed({
      get: () => root.$store.state.calendar.termText,
      set: (val) => root.$store.commit('calendar/setTermText', val)
    })
    const termOptions = computed({
      get: () => root.$store.state.calendar.terms,
      set: (val) => root.$store.commit('calendar/setTerms', val)
    })
    const noClasses = ref(false)
    const tab = ref(0)
    const credits = ref(0)
    const schedule = ref([])
    const eventMenuActive = ref(false)
    const selectedElement = ref(null)
    const selectedEvent = ref({})
    const buildings = ref({
      KRESGE: {
        name: 'Anna Emma Kresge Mem. Library',
        map: 'https://www.google.com/maps/place/Kresge+Memorial+Library/@34.9634823,-85.3752524,18z/data=!4m5!3m4!1s0x88605b478ef77be3:0xed6a0ba9c4d174ab!8m2!3d34.9638579!4d-85.3751582'
      },
      MILLS: {
        name: 'C G & Nancy Mills Science Bldg',
        map: 'https://www.google.com/maps/place/Mills+Hall,+Lookout+Mountain,+GA+30750/@34.9647086,-85.3766385,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b47804223ff:0x8519e45561689afc!8m2!3d34.9647064!4d-85.3755442'
      },
      SANDER: {
        name: 'Sanderson Hall',
        map: 'https://www.google.com/maps/place/Sanderson+Hall,+Lookout+Mountain,+GA+30750/@34.9633153,-85.3761544,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b47f29b9275:0xc6b6e337e0c81230!8m2!3d34.9633131!4d-85.3750601'
      },
      BARNES: {
        name: 'Barnes Physical Education Ctr',
        map: 'https://www.google.com/maps/place/Barnes+Physical+Education+Center/@34.9616528,-85.3775971,18z/data=!4m5!3m4!1s0x88605b72721a53ed:0x31608065c8bf66d8!8m2!3d34.9616993!4d-85.3765871'
      },
      ASHE: {
        name: 'Martha Ashe Activity Center',
        map: 'https://www.google.com/maps/place/Ashe+Activity+Center,+Lookout+Mountain,+GA+30750/@34.961143,-85.3779088,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b37eca6bddd:0x1754461fe6321ef3!8m2!3d34.9611408!4d-85.3768145'
      },
      FINART: {
        name: 'Chapel - D.M.Brown Fine Arts',
        map: 'https://www.google.com/maps/place/Dora+Maclellan+Brown+Memorial+Chapel,+Lookout+Mountain,+GA+30750/@34.964451,-85.3756679,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b479c19d3a5:0x9842c8ed91a4516c!8m2!3d34.9644488!4d-85.3745736'
      },
      JACKSN: {
        name: 'Jackson Hall',
        map: 'https://www.google.com/maps/place/Jackson+Hall,+Lookout+Mountain,+GA+30750/@34.9640257,-85.3778891,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b38684effdd:0x2dc547236f1b46b2!8m2!3d34.9640235!4d-85.3767948'
      },
      ANDREA: {
        name: 'Andreas Hall',
        map: 'https://www.google.com/maps/place/Andreas+Residence+Hall,+Lookout+Mountain,+GA+30750/@34.9624482,-85.3770823,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b38040a1177:0x2eb8e0e67322ffd6!8m2!3d34.962446!4d-85.375988'
      },
      BROCK: {
        name: 'Brock Hall',
        map: 'https://www.google.com/maps/place/Brock+Hall,+Lookout+Mountain,+GA+30750/@34.9652147,-85.3753529,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b477332bd71:0x48d1ff9b8722c077!8m2!3d34.9652125!4d-85.3742586'
      },
      KIRK: {
        name: 'The Kirk',
        map: 'https://www.google.com/maps/place/The+Kirk+(Covenant+College+Alumni+Center)/@34.9597568,-85.3769352,18z/data=!3m1!4b1!4m5!3m4!1s0x88605b7d950db32f:0x715ff8e33114e358!8m2!3d34.9597546!4d-85.3758409'
      },
      LUCAS: {
        name: 'Lucas Art Workshop',
        map: 'https://www.google.com/maps/place/Lucas+Art+Workshop/@34.9642745,-85.3789012,17z/data=!3m1!4b1!4m5!3m4!1s0x88605b43b8dce3cf:0x8df9f3b8e342b4bf!8m2!3d34.9642701!4d-85.3767125'
      },
      REMOTE: {
        name: 'Remote Learning'
      }
    })

    const isSmall = computed(() => {
      switch (root.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
      return true
    })
    const courseEvents = computed(() => {
      const weekDays = { M: 0, T: 1, W: 2, R: 3, F: 4, S: 5 }
      let [ year, month, day ] = calendarStart.value.split('-')
      day = parseInt(day)
      let arr = [
        { name: 'Chapel', start: year + '-' + month + '-' + day + ' 11:00', end: year + '-' + month + '-' + day + ' 11:35', color: 'grey' },
        { name: 'Chapel', start: year + '-' + month + '-' + (day + 2) + ' 11:00', end: year + '-' + month + '-' + (day + 2) + ' 11:35', color: 'grey' },
        { name: 'Chapel', start: year + '-' + month + '-' + (day + 4) + ' 11:00', end: year + '-' + month + '-' + (day + 4) + ' 11:35', color: 'grey' }
      ]
      // events in the calendar need name, start, and end; start and end need to be ISO formatted (YYYY-MM-DD HH:MI:SS
      for (let i = 0; i < schedule.value.length; i++) {
        let item = schedule.value[i]
        item.meetingBase.forEach(({ days, startTime, endTime }) => {
          let hour = parseInt(startTime.substr(0, startTime.search(':')))
          let minute = startTime.substr(startTime.search(':') + 1, 2)
          if (startTime.substr(-2) === 'pm' && hour < 12) hour += 12
          let start = (hour < 10 ? '0' : '') + hour + ':' + minute

          hour = parseInt(endTime.substr(0, endTime.search(':')))
          minute = endTime.substr(endTime.search(':') + 1, 2)
          if (endTime.substr(-2) === 'pm' && hour < 12) hour += 12
          let end = (hour < 10 ? '0' : '') + hour + ':' + minute

          for (let k = 0; k < days.length; k++) {
            arr.push({
              name: (item.regStatus === 'WL' ? '**Waitlisted** ' : '') + item.title,
              start: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + start,
              end: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + end,
              color: item.regStatus === 'WL' ? 'red' : 'primary'
            })
          }
        })
      }
      return arr
    })
    const calendarStart = ref('2021-08-23')

    function meetText (meetingBase) {
      if (meetingBase) {
        let str = []
        meetingBase.forEach(({ days, startTime, endTime }) => str.push(days + ' ' + startTime + ' - ' + endTime))
        return str.join('; ')
      }
      return ''
    }
    function roomText (meetingBase) {
      if (!meetingBase) return ''
      let arr = []
      meetingBase.forEach(({ building, room }) => {
        if (!room) return
        let included = false
        let str = room
        if (building in buildings.value) {
          let bldg = buildings.value[building]
          if ('map' in bldg) {
            str += ' (<a href="' + bldg.map + '" target="_blank">' + bldg.name + '</a>)'
          } else {
            str += ' (' + bldg.name + ')'
          }
        }
        arr.forEach(s => {
          if (str === s) included = true
        })
        if (!included) arr.push(str)
      })
      if (arr.length > 0) return '<b>Room: </b>' + arr.join('; ')
      else return ''
    }
    function showCalendarCourseDetails ({ nativeEvent, event }) {
      const open = () => {
        if (event.name === 'Chapel') {
          selectedEvent.value = {
            title: 'Chapel',
            meetingBase: [{
              room: 'Chapel Auditorium',
              days: 'MWF',
              startTime: '11:00am',
              endTime: '11:35am'
            }],
            regStatus: 'RE'
          }
        }
        let eventName = event.name
        if (event.name.substr(0, 15) === '**Waitlisted** ') {
          eventName = event.name.substr(15)
        }
        for (let i = 0; i < schedule.value.length; i++) {
          if (schedule.value[i].title === eventName) {
            selectedEvent.value = schedule.value[i]
          }
        }
        selectedElement.value = nativeEvent.target
        setTimeout(() => { eventMenuActive.value = true }, 10)
      }

      if (eventMenuActive.value) {
        eventMenuActive.value = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }

    const isEmployee = computed(() => user.value.roles.includes('Employee'))
    const selectedStudent = ref('')
    watch(selectedStudent, (val) => {
      if (val != null && val !== '' && val !== id.value && /^@[0-9]{8}$/.test(val)) {
        root.$router.push('/calendar/schedule/' + val)
      }
    })
    function clearStudent () {
      selectedStudent.value = ''
      schedule.value = []
      root.$router.push('/calendar/schedule')
    }
    const students = ref([])
    const studentSearch = ref('')
    watch(studentSearch, (val) => {
      if (val == null || val === '') {
        students.value = []
        return
      }
      let query = { term: term.value, $limit: 20, $select: ['pidm', 'term', 'bannerId', 'personal.preferred', 'personal.last'] }
      let regex = /@[0-9]{1,8}/i
      if (regex.test(val)) {
        let match = val.match('@[0-9]{1,8}')[0]
        if (match === id.value) return
        query['bannerId'] = { $regex: match }
      } else if (val.search(',') >= 0) {
        let [last, first] = val.split(',')
        last = last.trim()
        first = first.trim()
        query['personal.preferred'] = { $regex: first, $options: 'i' }
        query['personal.last'] = { $regex: last, $options: 'i' }
      } else if (val.search(' ') > 0) {
        let [first, last] = val.trim().split(' ')
        if (first != null && first !== '') {
          query['personal.preferred'] = { $regex: first, $options: 'i' }
        }
        if (last != null && last !== '') {
          query['personal.last'] = { $regex: last, $options: 'i' }
        }
      } else {
        query.$or = [
          { 'personal.preferred': { $regex: val.replace(' ', '[\\w ]'), $options: 'i' } },
          { 'personal.last': { $regex: val.replace(' ', '[\\w ]'), $options: 'i' } }
        ]
      }
      root.$feathers.service('student/confirmation').find({ query }).then(({ data }) => {
        students.value = []
        for (let i = 0; i < data.length; i++) {
          students.value.push({ text: data[i].personal.preferred + ' ' + data[i].personal.last + ' (' + data[i].bannerId + ')', value: data[i].bannerId })
        }
      })
    })

    watch(id, () => {
      if (id.value != null && id.value !== '' && term.value != null && term.value !== '') {
        root.$feathers.service('student/confirmation').find({ query: { bannerId: id.value, term: term.value, $select: ['pidm', 'term', 'bannerId', 'personal.preferred', 'personal.last'] } }).then(({ data }) => {
          if (data.length > 0) {
            loadSchedule(data[0])
          } else {
            noClasses.value = true
            schedule.value = []
            credits.value = 0
          }
          root.$feathers.service('student/confirmation').find({ query: { pidm: data[0].pidm, $select: ['term'] } }).then(({ data }) => {
            for (let j = 0; j < termOptions.value.length; j++) {
              let exists = false
              for (let i = 0; i < data.length; i++) {
                if (data[i].term === termOptions.value[j].term) exists = true
              }
              if (!exists && 'active' in termOptions.value[j]) {
                let temp = { ...termOptions.value[j] }
                delete temp.active
                termOptions.value.splice(j, 1, temp)
              } else if (exists && !('active' in termOptions.value[j])) {
                let temp = { ...termOptions.value[j], active: true }
                termOptions.value.splice(j, 1, temp)
              }
            }
          })
        })
      }
    })
    watch(term, () => {
      if (isEmployee.value && id.value != null && id.value !== '') {
        root.$feathers.service('student/confirmation').find({ query: { bannerId: id.value, term: term.value, $select: ['pidm', 'term', 'bannerId', 'personal.preferred', 'personal.last'] } }).then(({ data }) => {
          if (data.length > 0) {
            loadSchedule(data[0])
          } else {
            noClasses.value = true
            schedule.value = []
            credits.value = 0
          }
        })
      } else if (!isEmployee.value) {
        loadSchedule({ pidm: user.value.pidm, term: term.value })
      }
    })

    onMounted(async () => {
      // Check to see if the user is an employee; if so, we will check the passed Banner ID and load the schedule for that user
      if (isEmployee.value) {
        // Check to see if the user has access to a work-study list in the directory
        root.$feathers.service('directory/list').find({ query: { type: 'workstudy', $or: [{ owners: user.value.directoryId }, { 'share.user': user.value.directoryId }], $limit: 50 } }).then(({ data }) => {
          if (data.length > 0) {
            workstudyList.value = data
          }
        })
        // Populate the termOptions with all of the terms in the calendar/classes service
        await root.$store.dispatch('calendar/loadTerms')
        if (id.value != null && id.value !== '') {
          const { data } = await root.$feathers.service('student/confirmation').find({ query: { bannerId: id.value, term: term.value, $select: ['pidm', 'term', 'personal.preferred', 'personal.last', 'bannerId'] } })
          loadSchedule(data[0])
          students.value = [{ text: data[0].personal.preferred + ' ' + data[0].personal.last + ' (' + data[0].bannerId + ')', value: data[0].bannerId }]
          selectedStudent.value = data[0].bannerId
        }
      } else {
        // Populate the termOptions with all of the terms for this user in the calendar/classes service
        root.$feathers.service('calendar/classes').find({ query: { 'students.pidm': user.value.pidm, $distinct: 'term' } }).then(({ data }) => {
          let terms = []
          for (let i = 0; i < data.length; i++) {
            terms.push(data[i]._id)
          }
          root.$feathers.service('system/term').find({ query: { term: { $in: terms }, $sort: { start: -1 }, $limit: 50 } }).then(({ data }) => {
            termOptions.value = data
            term.value = data[0].term
            termText.value = data[0].label
            root.$feathers.service('calendar/classes').find({ query: { term: term.value, 'students.pidm': user.value.pidm } }).then(({ data }) => {
              if (data.length === 0) {
                noClasses.value = true
              } else {
                data.forEach((course) => {
                  if (course.instructors.length > 0) course.instructor = course.instructors[0]
                  schedule.value.push(course)
                  if ('credits' in course) credits.value += course.credits
                })
                tab.value = 0
              }
            })
          })
        })
      }
    })

    async function loadSchedule ({ pidm, term }, returnEvents) {
      let { data } = await root.$feathers.service('calendar/classes').find({ query: { term, 'students.pidm': pidm } })
      if (data.length === 0) {
        if (!returnEvents) noClasses.value = true
        else return []
      } else {
        noClasses.value = false
        if (returnEvents) {
          let arr = []
          data.forEach((course) => {
            if (course.instructors.length > 0) course.instructor = course.instructors[0]
            arr.push(course)
          })
          return arr
        } else {
          schedule.value = []
          credits.value = 0
          data.forEach((course) => {
            if (course.instructors.length > 0) course.instructor = course.instructors[0]
            for (let i = 0; i < course.students.length; i++) {
              if (course.students[i].pidm === pidm) {
                course.regStatus = course.students[i].regStatus
                break
              }
            }
            schedule.value.push(course)
            if ('credits' in course) credits.value += course.credits
          })
        }
      }
    }

    const selectedSchedule = ref('student')
    const selectedScheduleTitle = ref('Student Class Schedule')
    const workstudyList = ref([])

    function setSelectedSchedule (code) {
      const colors = ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey']
      selectedSchedule.value = code
      if (code === 'student') {
        selectedScheduleTitle.value = 'Student Class Schedule'
      } else {
        workstudyStudents.value = []
        workstudyEvents.value = []
        for (let i = 0; i < workstudyList.value.length; i++) {
          if (workstudyList.value[i].code === code) {
            selectedScheduleTitle.value = workstudyList.value[i].title
            root.$feathers.service('directory/people').find({ query: { _id: { $in: workstudyList.value[i].groups[0].people }, $limit: workstudyList.value[i].groups[0].people.length } }).then(async ({ data, total }) => {
              for (let i = 0; i < data.length; i++) {
                let color = colors[i % colors.length] + (i > colors.length ? ' darken-' + Math.floor(i / colors.length) * 2 : '')
                let category = data[i].name.first + ' ' + data[i].name.last
                workstudyStudents.value.push(category)
                let temp = await loadSchedule({ pidm: data[i].pidm, term: term.value }, true)
                const weekDays = { M: 0, T: 1, W: 2, R: 3, F: 4, S: 5 }
                let [ year, month, day ] = calendarStart.value.split('-')
                day = parseInt(day)
                workstudyEvents.value.push({ name: 'Chapel', start: year + '-' + month + '-' + day + ' 11:00', end: year + '-' + month + '-' + day + ' 11:35', color, category })
                workstudyEvents.value.push({ name: 'Chapel', start: year + '-' + month + '-' + (day + 2) + ' 11:00', end: year + '-' + month + '-' + (day + 2) + ' 11:35', color, category })
                workstudyEvents.value.push({ name: 'Chapel', start: year + '-' + month + '-' + (day + 4) + ' 11:00', end: year + '-' + month + '-' + (day + 4) + ' 11:35', color, category })
                // events in the calendar need name, start, and end; start and end need to be ISO formatted (YYYY-MM-DD HH:MI:SS
                for (let j = 0; j < temp.length; j++) {
                  let item = temp[j]
                  item.meetingBase.forEach(({ days, startTime, endTime }) => {
                    let hour = parseInt(startTime.substr(0, startTime.search(':')))
                    let minute = startTime.substr(startTime.search(':') + 1, 2)
                    if (startTime.substr(-2) === 'pm' && hour < 12) hour += 12
                    let start = (hour < 10 ? '0' : '') + hour + ':' + minute

                    hour = parseInt(endTime.substr(0, endTime.search(':')))
                    minute = endTime.substr(endTime.search(':') + 1, 2)
                    if (endTime.substr(-2) === 'pm' && hour < 12) hour += 12
                    let end = (hour < 10 ? '0' : '') + hour + ':' + minute

                    for (let k = 0; k < days.length; k++) {
                      workstudyEvents.value.push({
                        name: item.title,
                        start: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + start,
                        end: year + '-' + month + '-' + (day + weekDays[days[k]]) + ' ' + end,
                        color,
                        category
                      })
                    }
                  })
                }
              }
            })
            break
          }
        }
      }
    }

    const workstudyCalendar = ref(null)
    const workstudyCalendarDate = ref(calendarStart.value)
    const workstudyCalendarDays = ref([
      { text: 'Sunday', value: '2021-08-22' },
      { text: 'Monday', value: '2021-08-23' },
      { text: 'Tuesday', value: '2021-08-24' },
      { text: 'Wednesday', value: '2021-08-25' },
      { text: 'Thursday', value: '2021-08-26' },
      { text: 'Friday', value: '2021-08-27' },
      { text: 'Saturday', value: '2021-08-28' }
    ])
    const workstudyCalendarDay = computed(() => {
      let dt = new Date(workstudyCalendarDate.value + ' 8:00')
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      return days[dt.getDay()]
    })
    const workstudyStudents = ref([])
    const workstudyEvents = ref([])

    function prevDay () {
      workstudyCalendar.value.prev()
    }
    function nextDay () {
      workstudyCalendar.value.next()
    }

    return {
      user,
      id,
      term,
      termText,
      termOptions,
      noClasses,
      tab,
      credits,
      schedule,
      eventMenuActive,
      selectedElement,
      selectedEvent,
      buildings,
      isSmall,
      courseEvents,
      calendarStart,
      meetText,
      roomText,
      showCalendarCourseDetails,
      isEmployee,
      selectedStudent,
      clearStudent,
      students,
      studentSearch,
      selectedSchedule,
      selectedScheduleTitle,
      workstudyList,
      setSelectedSchedule,
      workstudyCalendar,
      workstudyCalendarDate,
      workstudyCalendarDays,
      workstudyCalendarDay,
      workstudyStudents,
      workstudyEvents,
      prevDay,
      nextDay
    }
  }
}
</script>
